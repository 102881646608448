import PropTypes from "prop-types"
import * as React from "react"
import MenuButton from "../components/MenuButton"
import Two4Seven from "../components/Two4Seven"

import gsap from "gsap"
import Cross from "./cross"
import Menu from "./menu"

import LinktoFormButton from "./LinktoFormButton"

export default class Header extends React.Component {
    constructor(props) {
        super(props)
        this.siteTitle = props.siteTitle
        this.openMenu = this.openMenu.bind(this)
        this.close = this.close.bind(this)
        this.displayLinks = this.displayLinks.bind(this)
        this.displayCorporateLinks = this.displayCorporateLinks.bind(this)
        this.displayEventLinks = this.displayEventLinks.bind(this)
        this.hoverLinks = this.hoverLinks.bind(this)
        this.hoverEventLinks = this.hoverEventLinks.bind(this)
        this.hoverGrey = this.hoverGrey.bind(this)
        this.hoverOutGrey = this.hoverOutGrey.bind(this)
        this.state = {
            menuColor: "",
            showMenu: false,
            mobile: false,
            isEventVisible: false,
            isCorporateVisible: false,
        }
    }

    displayLinks() {
        this.setState({ isEventVisible: false, isCorporateVisible: false })

        const links = document.querySelector("#page-links")
        if (window.innerWidth > 768) {
            if (links.style.visibility === "visible") {
                document.querySelector("#eventMenuButton").classList.remove("clicked")
                document.querySelector("#disoverMenuButton").classList.remove("clicked")
                document.querySelector("#corporateMenuButton").classList.remove("clicked")
                if (this.state.isEventVisible) return
                let tl = gsap.timeline()
                tl.add("first")
                tl.add("second")
                tl.to("#page-links", { opacity: 0, duration: 1, ease: "power1.out" }, "first")
                tl.to("#page-links", { visibility: "hidden" }, "second+=1")
            } else {
                document.querySelector("#disoverMenuButton").classList.add("clicked")
                let tl = gsap.timeline()
                tl.add("first")
                tl.add("second")
                tl.to("#page-links", { visibility: "visible" }, "first")

                tl.to("#page-links", { opacity: 1, duration: 1, ease: "power1.out" }, "second")
            }
        } else {
            this.setState({ showMenu: !this.state.showMenu })
        }
    }
    displayCorporateLinks() {
        this.setState({ isCorporateVisible: true, isEventVisible: false })

        const links = document.querySelector("#page-links")
        if (window.innerWidth > 768) {
            if (links.style.visibility === "visible") {
                document.querySelector("#disoverMenuButton").classList.remove("clicked")
                document.querySelector("#eventMenuButton").classList.remove("clicked")
                document.querySelector("#corporateMenuButton").classList.remove("clicked")
                if (!this.state.isCorporateVisible) return
                let tl = gsap.timeline()
                tl.add("first")
                tl.add("second")
                tl.to("#page-links", { opacity: 0, duration: 1, ease: "power1.out" }, "first")
                tl.to("#page-links", { visibility: "hidden" }, "second+=1")
            } else {
                document.querySelector("#corporateMenuButton").classList.add("clicked")
                let tl = gsap.timeline()
                tl.add("first")
                tl.add("second")
                tl.to("#page-links", { visibility: "visible" }, "first")
                tl.to("#page-links", { opacity: 1, duration: 1, ease: "power1.out" }, "second")
            }
        } else {
            this.setState({ showMenu: !this.state.showMenu })
        }
    }
    displayEventLinks() {
        this.setState({ isEventVisible: true, isCorporateVisible: false })

        const links = document.querySelector("#page-links")
        if (window.innerWidth > 768) {
            if (links.style.visibility === "visible") {
                document.querySelector("#disoverMenuButton").classList.remove("clicked")
                document.querySelector("#eventMenuButton").classList.remove("clicked")
                document.querySelector("#corporateMenuButton").classList.remove("clicked")
                if (!this.state.isEventVisible) return
                let tl = gsap.timeline()
                tl.add("first")
                tl.add("second")
                tl.to("#page-links", { opacity: 0, duration: 1, ease: "power1.out" }, "first")
                tl.to("#page-links", { visibility: "hidden" }, "second+=1")
            } else {
                document.querySelector("#eventMenuButton").classList.add("clicked")
                let tl = gsap.timeline()
                tl.add("first")
                tl.add("second")
                tl.to("#page-links", { visibility: "visible" }, "first")
                tl.to("#page-links", { opacity: 1, duration: 1, ease: "power1.out" }, "second")
            }
        } else {
            this.setState({ showMenu: !this.state.showMenu })
        }
    }

    hoverLinks(e) {
        document.querySelector(`#${e.target.id}Menu`)?.classList.replace("fade-out-5", "fade-out-1-alt")
        document.querySelector(`#${e.target.id}Menu`)?.classList.remove("o-0")
    }

    hoverOutLinks(e) {
        document.querySelector(`#${e.target.id}Menu`)?.classList.add("o-0")
        document.querySelector(`#${e.target.id}Menu`)?.classList.replace("fade-out-1-alt", "fade-out-5")
    }

    hoverEventLinks(e) {
        document.querySelector(`#${e.target.id}Menu`)?.classList.replace("fade-out-5", "fade-out-1-alt")
        document.querySelector(`#${e.target.id}Menu`)?.classList.remove("o-0")
        document.querySelector(`#${e.target.id}-image`)?.classList.replace("fade-out-5", "fade-out-1-alt")
        document.querySelector(`#${e.target.id}-image`)?.classList.remove("o-0")
    }

    hoverOutEventLinks(e) {
        document.querySelector(`#${e.target.id}Menu`)?.classList.add("o-0")
        document.querySelector(`#${e.target.id}Menu`)?.classList.replace("fade-out-1-alt", "fade-out-5")
        document.querySelector(`#${e.target.id}-image`)?.classList.add("o-0")
        document.querySelector(`#${e.target.id}-image`)?.classList.replace("fade-out-1-alt", "fade-out-5")
    }

    hoverGrey(e) {
        const disocverClicked = document.querySelector("#disoverMenuButton").classList.contains("clicked")
        if (!disocverClicked) {
            const links = document.querySelectorAll(".navF")
            links.forEach(link => {
                link.classList.add("grey")
            })
        }
    }
    hoverOutGrey(e) {
        /*
    if (!disocverClicked) {
      const links = document.querySelectorAll(".navF")
      links.forEach((link) => {
        link.classList.remove("grey")
      })
    }
    */
    }

    componentDidMount() {
        document.querySelectorAll("a").forEach(link => {
            if (window.location.pathname === link.pathname || window.location.pathname === link.pathname + "/") {
                if (link.querySelector("div.head")) {
                    let tl = gsap.timeline()
                    tl.to(link.querySelector("div.head"), { color: "white", duration: 0 })
                }
            } else if (window.location.pathname === ("/thegame" || "/thelife" || "/thetrip" || "/thetaste" || "/theculture" || "/thegame/" || "/thelife/" || "/thetrip/" || "/thetaste/" || "/theculture/")) {
                document.querySelector("#disoverMenuButton").style.color = "white"
            }
        })

        if (window.innerWidth < 768) {
            this.setState({ mobile: true })
        }
        document.body.scrollTop = 0

        document.addEventListener("scroll", () => {
            document.querySelectorAll(`.invertNav`)
        })
        const nav = document.querySelector("#nav-bar")
        const black = nav.classList.contains("black")
        if (black) {
            this.setState({ menuColor: "black" })
        } else {
            this.setState({ menuColor: "white" })
        }
    }
    openMenu() {
        function first() {
            let tl = gsap.timeline({ paused: true })

            tl.add("first")

            tl.to("#menu-button", { pointerEvents: "none" }, "first")
            tl.to("body", { pointerEvents: "none" }, "first")
            tl.to("#menu", { visibility: "visible", pointerEvents: "visible", position: "fixed" }, "first")
            if (window.innerWidth < 768) {
                tl.to("body", { overflow: "hidden" }, "first")
            }
            tl.to("#nav-bar", { borderBottom: `1px solid transparent`, ease: "power1.out", duration: 0.5, transition: "none" }, "first")
            tl.to("#menu-button", { opacity: 0, duration: 0.5, ease: "power1.out" }, "first")
            tl.to("#nav-bar", { background: "transparent", ease: "power1.out", duration: 0.5, transition: "none" }, "first+=0.1")
            tl.play()
            return tl
        }

        function second() {
            let tl = gsap.timeline({ paused: true })

            tl.add("second")

            tl.to("#menu", { opacity: 1, duration: 0.35, ease: "power1.out" }, "second")
            tl.to("#nav-bar div.button", { color: "white", duration: 1, ease: "power1.out" }, "second")
            tl.to("#nav-bar svg", { fill: `white`, duration: 1, ease: "power1.out" }, "second")

            tl.to("#menu-content", { visibility: "visible" }, "second")
            tl.play()
            return tl
        }

        function third() {
            let tl = gsap.timeline({ paused: true })
            tl.add("third")

            tl.to("#menu-content", { opacity: 1, duration: 1, ease: "power1.out" }, "third")
            tl.to("#cross", { visibility: "visible" }, "third")
            tl.to("#cross", { opacity: 1, duration: 1, ease: "power1.out" }, "third")
            tl.play()

            return tl
        }

        function five() {
            let tl = gsap.timeline({ paused: true })
            tl.add("five")
            tl.to("body", { pointerEvents: "visible" }, "five")
            tl.to("#cross", { pointerEvents: "visible" }, "five")

            tl.play()
            return tl
        }

        let master = gsap.timeline({ paused: true })

        master.add(first())

        master.call(() => {
            const openMenu = new CustomEvent("openMenu")
            document.dispatchEvent(openMenu)
        })

        master.add(second())
        master.add(third())

        master.add(five())
        master.play()
    }
    close() {
        let color = this.state.menuColor

        let back
        const nav = document.querySelector("#nav-bar")
        const tran = nav.classList.contains("back-tran")

        //invert color of background

        if (tran) {
            back = "transparent"
        } else if (!tran) {
            back = "white"
        } else if (color === "black") {
            back = "white"
        } else if (color === "white") {
            back = "black"
        }

        if (!tran) {
            color = "#2a2a2a"
        }

        const borderColor = back === "white" ? `rgba(0,0,0,0.45)` : "white"

        function intial() {
            let tl = gsap.timeline()
            tl.add("inital")
            tl.to("#cross", { pointerEvents: "none" }, "inital")
        }

        function first() {
            let tl = gsap.timeline()
            tl.add("first")
            tl.to("#menu-content", { opacity: 0, duration: 1, ease: "power1.out" }, "first")
            tl.to("#cross", { opacity: 0, duration: 1, ease: "power1.out" }, "first")
            tl.to("#menu-button", { visibility: "visible" }, "first")
            return tl
        }

        function second() {
            let tl = gsap.timeline()
            tl.add("second")
            tl.to("#menu", { opacity: 0, ease: "power1.out", duration: 0.35 }, "second")
            tl.to("#nav-bar", { backgroundColor: `${back}`, duration: 1, ease: "power1.out" }, "second+=1")

            if (!(window.location.pathname === "/")) {
                tl.to(
                    "#nav-bar",
                    {
                        borderBottom: `1px solid ${borderColor}`,
                        duration: 1,
                        ease: "power1.out",
                        transition: "none",
                    },
                    "second+=1.2"
                )
            } else {
                tl.to(
                    "#nav-bar",
                    {
                        borderBottom: `1px solid ${color}`,
                        duration: 1,
                        ease: "power1.out",
                    },
                    "second+=1.2"
                )
            }

            tl.to("#nav-bar svg", { fill: `${color}`, duration: 1, ease: "power1.out" }, "second+=0.2")
            tl.to("#nav-bar div.button", { color: `${color}`, duration: 1, ease: "power1.out" }, "second+=0.2")

            tl.to("#middle-bar", { borderBottom: `1px solid ${color}`, duration: 1, ease: "power1.out" }, "second+=0.5")
            tl.to(
                "#top-bars",
                {
                    borderBottom: `1px solid ${color}`,
                    borderTop: `1px solid ${color}`,
                    duration: 1,
                    ease: "power1.out",
                },
                "second+=0.5"
            )
            tl.to("body", { height: "unset", overflow: "unset" }, "second")
            tl.to("#menu-button", { opacity: 1, duration: 1, ease: "power1.out" }, "second+=0.5")

            return tl
        }

        function third() {
            let tl = gsap.timeline()
            tl.add("third")
            tl.to("#cross", { visibility: "hidden" }, "third")

            return tl
        }

        function five() {
            let tl = gsap.timeline()
            tl.add("five")

            tl.to("#page-links", { visibility: "hidden" }, "five")
            tl.to("#menu", { visibility: "hidden", pointerEvents: "none" }, "five")
            return tl
        }
        function finish() {
            let tl = gsap.timeline()
            tl.add("finish")
            tl.to("#menu-button", { pointerEvents: "visible" }, "finish")

            tl.to("#page-links", { visibility: "hidden", opacity: 0 }, "finish")
            return tl
        }

        let master = gsap.timeline({ paused: true })
        master.add(intial())
        master.add(first())
        master.add(second())
        master.add(third())
        //master.add( four () )
        master.add(five())
        master.add(finish())
        master.play()
    }

    render() {
        return (
            <>
                <Menu
                    mobile={this.state.mobile}
                    showMenu={this.state.showMenu}
                    displayCorporateLinks={this.displayCorporateLinks}
                    displayLinks={this.displayLinks}
                    displayEventLinks={this.displayEventLinks}
                    hoverLinks={this.hoverLinks}
                    hoverEventLinks={this.hoverEventLinks}
                    hoverOutLinks={this.hoverOutLinks}
                    hoverOutEventLinks={this.hoverOutEventLinks}
                    hoverGrey={this.hoverGrey}
                    hoverOutGrey={this.hoverOutGrey}
                    isEventVisible={this.state.isEventVisible}
                    isCorporateVisible={this.state.isCorporateVisible}
                />
                <div className={`flex flex-s-b fixed w-100-p p-t-15 p-b-15 p-r-40 p-l-40 z-5 b-b-1-w b-t ${this.props.className}`} id='nav-bar'>
                    <div className='flex flex-center-v w-33-33-p relative flex-order-2-br flex-end-br'>
                        <Cross close={this.close} />
                        <MenuButton openMenu={this.openMenu} />
                    </div>
                    <div className='flex flex-center-v w-33-33-p flex-j-center flex-order-1-br  c-w-33-33-p-br '>
                        <Two4Seven />
                    </div>
                    <div className='flex flex-center-v w-33-33-p flex-j-end d-n-b  '>
                        <LinktoFormButton isLink={this.props.isLink} to={this.props.link ?? "/contact"} text="I'm Interested" className='c-white ' classNameArrow='f-white' classNameDiv='c-white' />
                    </div>
                </div>
            </>
        )
    }
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}
